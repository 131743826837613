<template>
	<div class="mobile-views-validate-expense-details--component-wrapper" @click="handleCardClicked">
		<div class="card" v-for="(item, i) in items" :key="i">
			<div class="card-body">
				<div class="head">
					<span :class="[`badge badge-${item.validatedClass}`]">
						{{ item.validatedStatus }}
					</span>
					<span class="badge badge-light" style="margin-left: 10px">
						{{ item.departmentName }}
					</span>
				</div>
				<div class="content">
					<div class="info">
						<span>
							{{ FormMSG(1, 'Supplier Name') }}: <b>{{ item.supplierName }}</b>
						</span>
						<span>
							{{ FormMSG(2, 'Description') }}: <b>{{ item.description }}</b>
						</span>
						<span v-if="item.categoryName">
							{{ FormMSG(3, 'Category Name') }}: <b>{{ item.categoryName }}</b>
						</span>
						<span v-if="item.amountTotal">
							{{ FormMSG(4, 'Total') }}:
							<b>{{ rendCurrency(item.amountTotal) }}</b>
						</span>
					</div>
					<div class="actions">
						<b-button variant="success" v-if="item.images.length > 0" @click.prevent.stop="handleSbhowPicture">
							<component :is="getLucideIcon(ICONS.PICTURE.name)" :color="ICONS.PICTURE.color" :size="20" />
						</b-button>
						<b-button
							variant="danger"
							v-if="item.validated != 4 && item.validated != 16"
							size="sm"
							@click.prevent.stop="rejectExpenseItem(item.id)"
						>
							<i class="icon-action-undo"></i>
						</b-button>
						<b-button
							variant="success"
							size="sm"
							v-if="item.validated == 4 || item.validated == 16"
							@click.prevent.stop="undoRejectExpenseItem(item.id)"
						>
							{{ FormMSG(17, 'Undo') }}
							<i class="icon-action-undo"></i>
						</b-button>
					</div>
				</div>
				<div class="foot">
					<span v-html="rendDate(item.date)" class="date" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { rendCurrency, formatTableDate } from '~helpers';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'MobileViewsValidateExpenseDetailsListComponent',
	mixins: [languageMessages, globalMixin],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	methods: {
		handleCardClicked() {
			this.$emit('card-clicked', this.item);
		},
		handleSbhowPicture() {
			this.$emit('show-pictures', this.item.image);
		},
		validateExpenseItem() {
			this.$emit('validate-expense', this.item.id);
		},

		rejectExpenseItem(itemId) {
			this.$emit('reject-expense', itemId);
		},
		rejectExpenseItem(itemId) {
			this.$emit('undo-reject-expense', itemId);
		},

		/**
		 * @param {String or Number}
		 * @return {String}
		 */
		rendCurrency(price) {
			return rendCurrency(price);
		},

		/**
		 * @param {String}
		 * @return {String}
		 */
		rendDate(date) {
			return formatTableDate(date);
		}
	}
};
</script>

<style lang="scss" scoped>
.head,
.content {
	width: 100%;
}

.head {
	padding-bottom: 10px;
}
.content {
	display: flex;
	justify-content: space-between;

	.info span {
		display: inline-block;
		width: 100%;
	}
	.actions {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		button {
			margin-left: 20px;
		}
	}
}
</style>
