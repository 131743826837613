var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mobile-views-validate-expense-details--component-wrapper",
      on: { click: _vm.handleCardClicked },
    },
    _vm._l(_vm.items, function (item, i) {
      return _c("div", { key: i, staticClass: "card" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "head" }, [
            _c("span", { class: [`badge badge-${item.validatedClass}`] }, [
              _vm._v(
                "\n\t\t\t\t\t" + _vm._s(item.validatedStatus) + "\n\t\t\t\t"
              ),
            ]),
            _c(
              "span",
              {
                staticClass: "badge badge-light",
                staticStyle: { "margin-left": "10px" },
              },
              [
                _vm._v(
                  "\n\t\t\t\t\t" + _vm._s(item.departmentName) + "\n\t\t\t\t"
                ),
              ]
            ),
          ]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "info" }, [
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(_vm.FormMSG(1, "Supplier Name")) +
                    ": "
                ),
                _c("b", [_vm._v(_vm._s(item.supplierName))]),
              ]),
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(_vm.FormMSG(2, "Description")) +
                    ": "
                ),
                _c("b", [_vm._v(_vm._s(item.description))]),
              ]),
              item.categoryName
                ? _c("span", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(3, "Category Name")) +
                        ": "
                    ),
                    _c("b", [_vm._v(_vm._s(item.categoryName))]),
                  ])
                : _vm._e(),
              item.amountTotal
                ? _c("span", [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(4, "Total")) +
                        ":\n\t\t\t\t\t\t"
                    ),
                    _c("b", [
                      _vm._v(_vm._s(_vm.rendCurrency(item.amountTotal))),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "actions" },
              [
                item.images.length > 0
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "success" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.handleSbhowPicture.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(_vm.getLucideIcon(_vm.ICONS.PICTURE.name), {
                          tag: "component",
                          attrs: { color: _vm.ICONS.PICTURE.color, size: 20 },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                item.validated != 4 && item.validated != 16
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "danger", size: "sm" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.rejectExpenseItem(item.id)
                          },
                        },
                      },
                      [_c("i", { staticClass: "icon-action-undo" })]
                    )
                  : _vm._e(),
                item.validated == 4 || item.validated == 16
                  ? _c(
                      "b-button",
                      {
                        attrs: { variant: "success", size: "sm" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.undoRejectExpenseItem(item.id)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(17, "Undo")) +
                            "\n\t\t\t\t\t\t"
                        ),
                        _c("i", { staticClass: "icon-action-undo" }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "foot" }, [
            _c("span", {
              staticClass: "date",
              domProps: { innerHTML: _vm._s(_vm.rendDate(item.date)) },
            }),
          ]),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }